import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CanvasJSReact from '@canvasjs/react-charts';
import './App.css';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Home = () => {
  const [S107_01, set107_01] = useState([]);

  const fetch107_01 = async () => {
    try {
      const response = await axios.get('/107_01');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_01(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_01 Load', err);
    }
  };
  
  const [S107_02, set107_02] = useState([]);

  const fetch107_02 = async () => {
    try {
      const response = await axios.get('/107_02');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_02(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_02 Load', err);
    }
  };
  
  const [S107_03, set107_03] = useState([]);

  const fetch107_03 = async () => {
    try {
      const response = await axios.get('/107_03');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_03(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_03 Load', err);
    }
  };
  
  const [S107_04, set107_04] = useState([]);

  const fetch107_04 = async () => {
    try {
      const response = await axios.get('/107_04');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_04(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_04 Load', err);
    }
  };
  
  const [S107_05, set107_05] = useState([]);

  const fetch107_05 = async () => {
    try {
      const response = await axios.get('/107_05');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_05(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_05 Load', err);
    }
  };
  
  const [S107_07, set107_07] = useState([]);

  const fetch107_07 = async () => {
    try {
      const response = await axios.get('/107_07');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_07(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_07 Load', err);
    }
  };
  
  const [S107_08, set107_08] = useState([]);

  const fetch107_08 = async () => {
    try {
      const response = await axios.get('/107_08');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_08(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_08 Load', err);
    }
  };
  
   const [S107_09, set107_09] = useState([]);
   
    const fetch107_09 = async () => {
    try {
      const response = await axios.get('/107_09');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_09(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_09 Load', err);
    }
  };
  
	const [S107_10, set107_10] = useState([]);
  
    const fetch107_10 = async () => {
    try {
      const response = await axios.get('/107_10');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_10(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_10 Load', err);
    }
  };
  
  const [S107_11, set107_11] = useState([]);
  
    const fetch107_11 = async () => {
    try {
      const response = await axios.get('/107_11');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_11(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_11 Load', err);
    }
  };
  
    const [S107_12, set107_12] = useState([]);
  
    const fetch107_12 = async () => {
    try {
      const response = await axios.get('/107_12');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_12(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_12 Load', err);
    }
  };
  
    const [S107_13, set107_13] = useState([]);
  
    const fetch107_13 = async () => {
    try {
      const response = await axios.get('/107_13');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_13(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_13 Load', err);
    }
  };
  
    const [S107_14, set107_14] = useState([]);
  
    const fetch107_14 = async () => {
    try {
      const response = await axios.get('/107_14');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_14(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_14 Load', err);
    }
  };
  
    const [S107_15, set107_15] = useState([]);
  
    const fetch107_15 = async () => {
    try {
      const response = await axios.get('/107_15');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_15(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_15 Load', err);
    }
  };
  
      const [S107_16, set107_16] = useState([]);
  
    const fetch107_16 = async () => {
    try {
      const response = await axios.get('/107_16');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_16(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_16 Load', err);
    }
  };
  
      const [S107_17, set107_17] = useState([]);
  
    const fetch107_17 = async () => {
    try {
      const response = await axios.get('/107_17');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_17(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_17 Load', err);
    }
  };
  
      const [S107_18, set107_18] = useState([]);
  
    const fetch107_18 = async () => {
    try {
      const response = await axios.get('/107_18');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_18(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_18 Load', err);
    }
  };
  
      const [S107_19, set107_19] = useState([]);
  
    const fetch107_19 = async () => {
    try {
      const response = await axios.get('/107_19');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_19(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_19 Load', err);
    }
  };
  
        const [S107_20, set107_20] = useState([]);
  
    const fetch107_20 = async () => {
    try {
      const response = await axios.get('/107_20');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_20(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_20 Load', err);
    }
  };
  
        const [S107_21, set107_21] = useState([]);
  
    const fetch107_21 = async () => {
    try {
      const response = await axios.get('/107_21');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_21(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_21 Load', err);
    }
  };
  
        const [S107_22, set107_22] = useState([]);
  
    const fetch107_22 = async () => {
    try {
      const response = await axios.get('/107_22');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_22(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_22 Load', err);
    }
  };
  
        const [S107_25, set107_25] = useState([]);
  
    const fetch107_25 = async () => {
    try {
      const response = await axios.get('/107_25');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set107_25(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 107_25 Load', err);
    }
  };
  
        const [S71_12, set71_12] = useState([]);
  
    const fetch71_12 = async () => {
    try {
      const response = await axios.get('71_12');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        set71_12(formattedData);
      }
    } catch (err) {
      console.log('Error fetching 71_12 Load', err);
    }
  };
  
        const [EMERSONGEN, setEMERSONGEN] = useState([]);
  
    const fetchEMERSONGEN = async () => {
    try {
      const response = await axios.get('/EMERSONGEN');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        setEMERSONGEN(formattedData);
      }
    } catch (err) {
      console.log('Error fetching EMERSONGEN Load', err);
    }
  };
  
        const [MERSONTOWN, setMERSONTOWN] = useState([]);
  
    const fetchMERSONTOWN = async () => {
    try {
      const response = await axios.get('/MERSONTOWN');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        setMERSONTOWN(formattedData);
      }
    } catch (err) {
      console.log('Error fetching MERSONTOWN Load', err);
    }
  };
          const [RATTLESNAKE, setRATTLESNAKE] = useState([]);
  
    const fetchRATTLESNAKE = async () => {
    try {
      const response = await axios.get('/RATTLESNAKE');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        setRATTLESNAKE(formattedData);
      }
    } catch (err) {
      console.log('Error fetching RATTLESNAKE Load', err);
    }
  };
          const [WAKEFIELDGEN, setWAKEFIELDGEN] = useState([]);
  
    const fetchWAKEFIELDGEN = async () => {
    try {
      const response = await axios.get('/WAKEFIELDGEN');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        setWAKEFIELDGEN(formattedData);
      }
    } catch (err) {
      console.log('Error fetching WAKEFIELDGEN Load', err);
    }
  };
          const [WAKEFIELDMAIN, setWAKEFIELDMAIN] = useState([]);
  
    const fetchWAKEFIELDMAIN = async () => {
    try {
      const response = await axios.get('/WAKEFIELDMAIN');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        setWAKEFIELDMAIN(formattedData);
      }
    } catch (err) {
      console.log('Error fetching WAKEFIELDMAIN Load', err);
    }
  };
          const [Winside_WAPA, setWinside_WAPA] = useState([]);
  
    const fetchWinside_WAPA= async () => {
    try {
      const response = await axios.get('/Winside_WAPA');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        setWinside_WAPA(formattedData);
      }
    } catch (err) {
      console.log('Error fetching Winside_WAPA Load', err);
    }
  };
  
    const [SysLoad, setSysLoad] = useState([]);
  
    const fetchSysLoad = async () => {
    try {
      const response = await axios.get('/SystemLoad');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW,
		  reporting: item.REPORTING
        }));
        setSysLoad(formattedData);
      }
    } catch (err) {
      console.log('Error fetching SysLoad Load', err);
    }
  };

  useEffect(() => {
	document.title = "Substation Load Dashboard";
	fetchSysLoad();
    fetch107_01();
	fetch107_02();
	fetch107_03();
	fetch107_04();
	fetch107_05();
	fetch107_07();
	fetch107_08();
	fetch107_09();
	fetch107_10();
	fetch107_11();
	fetch107_12();
	fetch107_13();
	fetch107_14();
	fetch107_15();
	fetch107_16();
	fetch107_17();
	fetch107_18();
	fetch107_19();
	fetch107_20();
	fetch107_21();
	fetch107_22();
	fetch107_25();
	fetch71_12();
	fetchEMERSONGEN();
	fetchMERSONTOWN();
	fetchRATTLESNAKE();
	fetchWAKEFIELDGEN();
	fetchWAKEFIELDMAIN();
	fetchWinside_WAPA();
  }, []);
  
    const navigateToSystemInfo = () => {
    window.location.href = 'https://system.northeastpow.info';
  };

  const SysLoadOp = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "Total System Load + 5MW Wind"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: SysLoad,
	  toolTipContent: "Date: {x}<br/>KW: {y}<br/>Reporting: {reporting}"
    }]
  };

  const S107_01Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_01 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_01
    }]
  };
  
    const S107_02Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_02 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_02
    }]
  };
  
      const S107_03Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_03 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_03
    }]
  };
  
      const S107_04Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_04 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_04
    }]
  };
  
      const S107_05Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_05 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_05
    }]
  };
  
        const S107_07Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_07 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_07
    }]
  };
  
        const S107_08Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_08 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_08
    }]
  };
  
        const S107_09Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_09 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_09
    }]
  };
  
          const S107_10Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_11 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_11
    }]
  };
  
          const S107_11Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_11 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_11
    }]
  };
  
          const S107_12Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_12 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_12
    }]
  };
  
          const S107_13Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_13 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_13
    }]
  };
  
          const S107_14Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_14 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_14
    }]
  };
  
          const S107_15Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_15 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_15
    }]
  };
  
          const S107_16Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_16 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_16
    }]
  };
  
          const S107_17Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_17 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_17
    }]
  };
  
          const S107_18Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_18 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_18
    }]
  };
  
          const S107_19Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_19 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_19
    }]
  };
  
          const S107_20Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_20 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_20
    }]
  };
  
          const S107_21Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_21 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_21
    }]
  };
  
          const S107_22Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_22 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_22
    }]
  };
  
          const S107_25Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "107_25 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S107_25
    }]
  };
  
          const S71_12Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "71_12 Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: S71_12
    }]
  };
  
          const EMERSONGENOp = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "EMERSONGEN Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: EMERSONGEN
    }]
  };
  
          const MERSONTOWNOp = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "EMERSONTOWN Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: MERSONTOWN
    }]
  };
  
          const RATTLESNAKEOp = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "RATTLESNAKE Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: RATTLESNAKE
    }]
  };
  
          const WAKEFIELDGENOp = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "WAKEFIELDGEN Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: WAKEFIELDGEN
    }]
  };
  
          const WAKEFIELDMAINOp = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "WAKEFIELDMAIN Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: WAKEFIELDMAIN
    }]
  };
  
          const Winside_WAPAOp = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "Winside_WAPA Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: Winside_WAPA
    }]
  };
  
 return (
    <div>
	  <div className="header">
		<p>NORTHEAST POWER LOAD</p>
		<p>79.7mw MAX System Load</p>
		<p>These graphs may take a minute to load</p>
	  </div>
	  <div className="button">
		<button onClick={navigateToSystemInfo}>Irrigation Load Dashboard</button>
	  </div>
	  <div className="charts-container">
        <div className="chart-item"><CanvasJSChart options={SysLoadOp} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_01Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_02Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_03Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_04Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_05Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_07Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_08Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_09Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_10Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_11Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_12Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_13Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_14Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_15Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_16Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_17Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_18Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_19Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_20Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_21Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_22Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S107_25Op} /></div>
		<div className="chart-item"><CanvasJSChart options={S71_12Op} /></div>
		<div className="chart-item"><CanvasJSChart options={EMERSONGENOp} /></div>
		<div className="chart-item"><CanvasJSChart options={MERSONTOWNOp} /></div>
		<div className="chart-item"><CanvasJSChart options={RATTLESNAKEOp} /></div>
		<div className="chart-item"><CanvasJSChart options={WAKEFIELDGENOp} /></div>
		<div className="chart-item"><CanvasJSChart options={WAKEFIELDMAINOp} /></div>
		<div className="chart-item"><CanvasJSChart options={Winside_WAPAOp} /></div>	
      </div>
    </div>
  );
};

export default Home;
